import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

export default function Setting() {
  const [info, setInfo] = useState({ username: "", email: "", plan: "" });
  const [customerId, setCustomerId] = useState(null); // ID du client Stripe
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [infos, setInfos] = useState({
    password1: "",
    password2: "",
    password3: "",
  });

  useEffect(() => {
    // Récupérer les infos de l'utilisateur, y compris l'ID client Stripe
    axios
      .post("https://qqv-invest.fr/", { type: "user" })
      .then((res) => {
        setInfo({
          username: res.data.username,
          email: res.data.email,
          plan: res.data.plan,
        });
        setCustomerId(res.data.client_id); // Récupérer l'ID du client Stripe
      })
      .catch((err) => {
        setError("Erreur lors de la récupération des infos.");
      });
  }, []);

  // Changer le mot de passe
  function change(e) {
    setError("");
    setInfos({ ...infos, [e.target.name]: e.target.value });
  }

  function send(e) {
    e.preventDefault();
    if (infos.password2 !== infos.password3) {
      setError("Les mots de passe ne correspondent pas !");
    } else if (!infos.password1 || !infos.password2 || !infos.password3) {
      setError("Remplissez tout le formulaire !");
    } else if (infos.password1 === infos.password2) {
      setError("Saisissez un mot de passe différent de l'ancien");
    } else {
      axios
        .post("https://qqv-invest.fr/checkpassword", {
          email: info.email,
          password1: infos.password1,
        })
        .then((res) => {
          if (!res.data.exist) {
            setError("L'ancien mot de passe est incorrect !");
          } else {
            axios
              .post("https://qqv-invest.fr/changepassword", {
                email: info.email,
                password2: infos.password2,
              })
              .then((res) => {
                if (res.data.success) {
                  alert("Mot de passe changé avec succès !");
                  axios
                    .post("https://qqv-invest.fr/logout", { type: "user" })
                    .then((res) => {
                      if (res.data.success) {
                        window.location.reload(true);
                      }
                    });
                }
              });
          }
        });
    }
  }

  const handleCloseModal = () => setShowModal(false);

  const handleManageSubscription = () => {
    if (!customerId) {
      setError("Aucun ID client Stripe trouvé.");
      return;
    }

    // Requête pour ouvrir le portail de gestion d'abonnement
    axios
      .get(`https://qqv-invest.fr/customers/${customerId}`)
      .then((response) => {
        window.location.href = response.data.url; // Redirection vers le portail Stripe
      })
      .catch((err) => {
        setError("Erreur lors de la redirection vers le portail.");
      });
  };

  return (
    <div className="container mt-4">
      <div className="row">
        {/* User Settings Card */}
        <div className="col-md-6 mb-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Paramètres</h3>
              <p className="card-text">Nom d'utilisateur : {info.username}</p>
              <p className="card-text">Email : {info.email}</p>
              {error && <div className="alert alert-danger">{error}</div>}
              <button
                className="btn btn-primary"
                onClick={() => setShowModal(true)}
              >
                Changer le mot de passe
              </button>
            </div>
          </div>
        </div>

        {/* Subscription Offer Card */}
        {(info.plan === "pro" || info.plan === "starter") && (
          <div className="col-md-6 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Mon abonnement</h3>
                <p className="card-text">Mon plan : {info.plan}</p>
                <button
                  className="btn btn-primary"
                  onClick={handleManageSubscription}
                >
                  Gérer mon abonnement
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
