import { useState, useEffect } from "react";
import axios from "axios";
import logo from "../Assets/logo.jpg";
import { useNavigate } from "react-router-dom";

export default function Abonnement() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [essaie, setEssaie] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [annualPrice, setAnnualPrice] = useState(0);
  const [planType, setPlanType] = useState("monthly"); // Default to monthly
  const [id, setId] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.post("https://qqv-invest.fr/", {
          type: "user",
        });
        setId(res.data.id);
        console.log(res.data);

        if (res.data.success) {
          setEmail(res.data.email);
          setEssaie(res.data.essaie);
        } else {
          navigate("/login");
        }
      } catch (error) {}
    };

    const fetchPrices = async () => {
      try {
        const res = await axios.get("https://qqv-invest.fr/prices");
        setMonthlyPrice(res.data.monthlyPrice);
        setAnnualPrice(res.data.annualPrice);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchUserData();
    fetchPrices();
  }, [navigate]);

  useEffect(() => {
    axios.post("https://qqv-invest.fr/checksubs", { id }).then((res) => {
      if (res.data.status) {
        handleLogout();
      }
    });
  }, [id]);

  const getPlanType = () => {
    if (planType === "monthly") {
      return "starter"; // Assume 'pro' is for monthly plan
    } else {
      return "pro"; // Assume 'starter' is for annual plan
    }
  };

  const handleSubscribe = async () => {
    const plan = getPlanType(); // Get the plan type based on the selection
    try {
      const response = await axios.get("https://qqv-invest.fr/subscribe", {
        params: { plan, email }, // Pass the plan type and email
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const res = await axios.post("https://qqv-invest.fr/logout", {
        type: "user",
      });
      if (res.data.success) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleSubscribe1 = async (plan) => {
    try {
      const response = await axios.get(`https://qqv-invest.fr/subscribe`, {
        params: { plan, email },
        headers: { "Content-Type": "application/json" },
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };
  return (
    <div className="container mt-5 text-center">
      <header className="App-header">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10 d-flex justify-content-center">
            <div className="card mt-3">
              <div className="text-center mb-2">
                <img
                  src={logo}
                  alt="Logo"
                  className="img-fluid"
                  style={{ maxWidth: "140px", height: "auto" }}
                />
              </div>

              {essaie == "true" && (
                <div className="form-group d-flex justify-content-between align-items-center">
                  <select
                    id="planTypeSelect"
                    className="form-control w-50"
                    value={planType}
                    onChange={(e) => setPlanType(e.target.value)}
                  >
                    <option value="monthly">Plan Mensuel</option>
                    <option value="annual">Plan Annuel</option>
                  </select>
                </div>
              )}

              <div className="card-body">
                <h2>Devenir membre</h2>

                {essaie == "true" ? (
                  <>
                    <h3>
                      Tarif {planType === "monthly" ? "mensuel" : "annuel"}
                    </h3>
                    <p className="card-text">
                      {planType === "monthly"
                        ? `${monthlyPrice} € / mois`
                        : `${annualPrice} € / an`}
                    </p>
                    <p>
                      Abonnez-vous pour profiter de toutes les fonctionnalités
                      QQR Invest !
                    </p>
                    <p>Toutes les fonctionnalités QQR Invest.</p>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubscribe}
                      >
                        S'abonner
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="text-center">
                    <p>Essayer l'application pendant une semaine</p>
                    <p>Toutes les fonctionnalités incluses !</p>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSubscribe1("free")}
                      >
                        Démarrer l'essai gratuit
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-center">
                <button className="btn btn-danger" onClick={handleLogout}>
                  Se déconnecter
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
